exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-my-sources-js": () => import("./../../../src/pages/mySources.js" /* webpackChunkName: "component---src-pages-my-sources-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-protected-js": () => import("./../../../src/pages/protected.js" /* webpackChunkName: "component---src-pages-protected-js" */),
  "component---src-pages-security-confirm-sign-up-js": () => import("./../../../src/pages/security/confirmSignUp.js" /* webpackChunkName: "component---src-pages-security-confirm-sign-up-js" */),
  "component---src-pages-security-forgot-password-js": () => import("./../../../src/pages/security/forgotPassword.js" /* webpackChunkName: "component---src-pages-security-forgot-password-js" */),
  "component---src-pages-security-profile-js": () => import("./../../../src/pages/security/profile.js" /* webpackChunkName: "component---src-pages-security-profile-js" */),
  "component---src-pages-sources-js": () => import("./../../../src/pages/sources.js" /* webpackChunkName: "component---src-pages-sources-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-breakingnews-js": () => import("./../../../src/templates/breakingnews.js" /* webpackChunkName: "component---src-templates-breakingnews-js" */),
  "component---src-templates-catnews-js": () => import("./../../../src/templates/catnews.js" /* webpackChunkName: "component---src-templates-catnews-js" */),
  "component---src-templates-newsdetails-js": () => import("./../../../src/templates/newsdetails.js" /* webpackChunkName: "component---src-templates-newsdetails-js" */)
}

