import React, { useState } from "react"
import { ArticleConsumer } from "./player-context"
import ReactPlayer from "react-player/lazy"
import { Link } from "gatsby"
import { speech_url } from "../../static/scripts/config"

function Player(props) {
  const article = props.article
  const articles = props.articles
  let currentIndex = null

  if (articles && article)
    currentIndex = props.articles.findIndex(x => x.id == props.article.id)

  const [muted, setMuted] = React.useState(false)

  const url = article
    ? `${speech_url}${article.id}_0.mp3`
    : articles
    ? `${speech_url}${articles[0]}_0.mp3`
    : ""
  return (
    <>
      <ArticleConsumer>
        {playerContext => (
          <>
            {article ? (
              <div
                className={`audio-player ${
                  playerContext.playing ? "is-playing" : ""
                } ${muted ? "is-muted" : ""}`}
                style={{ display: "block" }}
              >
                <div className="container">
                  <div className="player-flex clearfix">
                    <img
                      referrerPolicy="no-referrer"
                      onError={i => (i.target.style.display = "none")}
                      onLoad={i => (i.target.style.display = "")}
                      alt={article.title}
                      src={article.image}
                      loading="lazy"
                      className="player-photo"
                    />
                    <div className="player-wrapper">
                      <div className="play-title clearfix">
                        <div className="the-title">
                          <Link
                            className="clear-a"
                            to={`/article/${article.id}`}
                          >
                            {article.title}
                          </Link>{" "}
                        </div>

                        <div
                          className="close-player floatleft"
                          onClick={() => {
                            playerContext.setCurrentPlaying(null)
                            playerContext.setPlaying(true)
                            playerContext.setPlayed(0)
                          }}
                        >
                          <i className="fal fa-times"></i>
                        </div>
                      </div>
                      <div className="the-player clearfix">
                        {props.playAll && (
                          <div
                            style={{ color: currentIndex == 0 ? "gray" : "" }}
                            className="change-track floatright"
                            onClick={() => {
                              if (currentIndex != 0) {
                                playerContext.setCurrentPlaying(
                                  articles[currentIndex - 1]
                                )
                                playerContext.setPlayed(0)
                                playerContext.setDuration(0)
                              }
                            }}
                          >
                            <i className="fal fa-step-forward"></i>
                          </div>
                        )}
                        <div
                          className="play-and-pause floatright"
                          onClick={() => {
                            playerContext.setPlaying(!playerContext.playing)
                          }}
                        ></div>
                        {props.playAll && (
                          <div
                            style={{
                              color:
                                currentIndex >= articles.length - 1
                                  ? "gray"
                                  : "",
                            }}
                            className="change-track floatright"
                            onClick={() => {
                              if (currentIndex < articles.length - 1) {
                                playerContext.setCurrentPlaying(
                                  articles[currentIndex + 1]
                                )
                                playerContext.setPlayed(0)
                                playerContext.setDuration(0)
                              }
                            }}
                          >
                            <i className="fal fa-step-backward"></i>
                          </div>
                        )}
                        <div className="track-bar floatright clearfix">
                          <div className="current-time floatright">
                            <time
                              dateTime={`P${Math.round(
                                playerContext.duration * playerContext.played
                              )}S`}
                            >
                              {format(
                                playerContext.duration * playerContext.played
                              )}
                            </time>
                          </div>
                          <div className="the-bar floatright">
                            <div className="play-progress">
                              <div className="the-progress-bar"></div>
                              <div className="the-progress-bar">
                                <span
                                  className="player-seeker"
                                  style={{
                                    width: `${playerContext.played * 100}%`,
                                  }}
                                ></span>
                              </div>
                            </div>
                          </div>
                          <div className="audio-ducation floatleft">
                            <time
                              dateTime={`P${Math.round(
                                playerContext.duration *
                                  (1 - playerContext.played)
                              )}S`}
                            >
                              {format(
                                playerContext.duration *
                                  (1 - playerContext.played)
                              )}
                            </time>
                          </div>
                        </div>
                        <div
                          className="volume floatleft"
                          onClick={() => setMuted(!muted)}
                        >
                          <div className="volume-state"></div>
                          <div className="volume-controls">
                            <div className="volume-bar">
                              <div className="the-bar">
                                <span className="track-circle">
                                  <i className="fas fa-circle"></i>
                                </span>
                              </div>
                            </div>
                            <div className="mute-state"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <ReactPlayer
              url={url}
              onError={() => console.info("player source error")}
              playing={playerContext.playing}
              onProgress={e => playerContext.setPlayed(e.played)}
              onDuration={e => playerContext.setDuration(e)}
              onPlay={() => console.log("onplay")}
              width="0"
              height="0"
              muted={muted}
              onEnded={() => {
                if (playerContext.playAll) {
                  playerContext.setCurrentPlaying(articles[currentIndex + 1])
                } else {
                  playerContext.setCurrentPlaying(null)
                }
                playerContext.setPlaying(true)
                playerContext.setPlayed(0)
              }}
              progressInterval="100"
            ></ReactPlayer>
          </>
        )}
      </ArticleConsumer>
    </>
  )
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ("0" + string).slice(-2)
}

export default Player
