const awsmobile = {
  aws_appsync_graphqlEndpoint: "https://azgmhbmci5f5xd6hlzlj5bhnky.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-t72vno4bpvfy3mrfoglud7ju7a",
  aws_project_region:"us-east-2",
  aws_cognito_identity_pool_id: "us-east-2:a359dda2-4b0e-426d-9571-e486d3fcb800",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_VqAExJAw3",
  aws_user_pools_web_client_id: "7mql6mdco5p0l6tf0a8h8rrj3c",
  federationTarget: 'COGNITO_USER_POOLS',
    awsCognitoAuthPlugin: {
        CognitoUserPool: {
            Default: {
                PoolId: "us-east-2:a359dda2-4b0e-426d-9571-e486d3fcb800",
                AppClientId: "7mql6mdco5p0l6tf0a8h8rrj3c",
                Region: "us-east-2"
            }
        }
    }

}

export default awsmobile