import React from "react"
import Player from "./src/components/player"
import {
  ArticleProvider,
  ArticleConsumer,
} from "./src/components/player-context"

import Amplify from "aws-amplify"
import awsmobile from "./static/scripts/aws-exports"
Amplify.configure(awsmobile)
console.log(Amplify)
console.log(awsmobile)

export function wrapPageElement({ element, props }, options) {
  return (
    <>
      <ArticleProvider>
        {element}
        <ArticleConsumer>
          {PlayerContext => (
            <Player
              article={PlayerContext.state}
              articles={PlayerContext.playlist}
              setPlaylist={PlayerContext.setPlaylist}
              futureArticles={PlayerContext.futurePlaylist}
              playAll={PlayerContext.playAll}
              playing={PlayerContext.playing}
              played={PlayerContext.played}
              duration={PlayerContext.duration}
              playlistTitle={PlayerContext.playlistTitle}
              setPlaylistTitle={PlayerContext.setPlaylistTitle}
            />
          )}
        </ArticleConsumer>
      </ArticleProvider>
    </>
  )
}

export function onRouteUpdate() {
  Amplify.configure(awsmobile)
  console.log(Amplify)
  console.log(awsmobile)

  const script = document.createElement("script")
  script.type = "text/javascript"
  script.text = "try{pageReady();}catch{}"
  document.body.appendChild(script)

  try {
    if (
      !window.pagePath.startsWith("/article") &&
      !window.pagePath.startsWith("/archive") &&
      !window.pagePath.startsWith("/404") &&
      !window.pagePath.startsWith("/terms-conditions") &&
      !window.pagePath.startsWith("/privacy") &&
      !window.pagePath.startsWith("/world") &&
      !window.pagePath.startsWith("/breaking") &&
      !window.pagePath.startsWith("/business") &&
      !window.pagePath.startsWith("/techscience") &&
      !window.pagePath.startsWith("/sport") &&
      !window.pagePath.startsWith("/health") &&
      !window.pagePath.startsWith("/entertainment") &&
      !window.pagePath.startsWith("/security") &&
      !window.pagePath.startsWith("/sources") &&
      !window.pagePath.startsWith("/mySources")
    ) {
      var value = window.pagePath.substr(0, 4)
      if (!value.endsWith("/")) value += "/"
      localStorage.setItem("countryCode", value)
    }
  } catch {
    console.error("localStorage countryCode is not set")
  }
}
