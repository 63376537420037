import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"

const PlayerContext = React.createContext()

export function ArticleProvider(props) {
  const data = useStaticQuery(graphql`
    query {
      nshra {
        topNews(cat: "all", countryCode: "AE") {
          articles {
            id
            image
            link
            predicted_category
            published
            rank
            source_category
            sourcename
            title
          }
        }
      }
    }
  `)

  const [currentPlaying, setCurrentPlaying] = React.useState(null)
  const [playlist, setPlaylist] = React.useState(data.nshra.topNews.articles)
  const [futurePlaylist, setFuturePlaylist] = React.useState({})
  const [playAll, setPlayAll] = React.useState(false)
  const [playing, setPlaying] = React.useState(true)
  const [played, setPlayed] = React.useState(0)
  const [duration, setDuration] = React.useState(0)
  const [playlistTitle, setPlaylistTitle] = React.useState("النشره")

  return (
    <PlayerContext.Provider
      value={{
        state: currentPlaying,
        setCurrentPlaying,
        playlist: playlist,
        setPlaylist,
        futurePlaylist,
        setFuturePlaylist,
        playAll,
        setPlayAll,
        playing,
        setPlaying,
        played,
        setPlayed,
        duration,
        setDuration,
        playlistTitle,
        setPlaylistTitle,
      }}
      {...props}
    />
  )
}

export class ArticleConsumer extends Component {
  render() {
    return (
      <PlayerContext.Consumer>{this.props.children}</PlayerContext.Consumer>
    )
  }
}
